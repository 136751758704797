.search-box-all {
    position: absolute;
    width: 100%;
    z-index: 22;
    border-radius: 50px;
    border: 1px solid rgb(172, 172, 172);
    overflow: hidden;
    transition: all .3s ease-in-out;
}

.suggestions-box.light {
    list-style: none;
    padding: 10px 30px;
    background-color: rgb(245, 245, 245);
    transition: all .3s ease-in-out;
}

.suggestions-box.dark {
    list-style: none;
    padding: 10px 30px;
    background-color: rgb(82, 82, 82);
    color: white;
    transition: all .3s ease-in-out;
}

.search-bars ul li {
    margin-bottom: 10px;
    cursor: pointer;
    transition: all .3s ease-in-out;
}

.search-bars ul li:hover {
    margin-left: 3px;
    text-shadow: 0px 0px 3px rgb(80,227,194);
}

.search-container{
    display: flex;
    transition: all .3s ease-in-out;
}

.search-bar.light {
    width: 100%;
    font-size: 18px;
    padding: 20px 30px;
    border: none;
    outline: none;
    background-color: transparent;
}

.search-bar.dark {
    width: 100%;
    font-size: 18px;
    padding: 20px 30px;
    border: none;
    outline: none;
    background-color: transparent;
    color: white;
}

.search-box-all:focus-within {
    outline: none;
    box-shadow: 0px 0px 6px 0px #b4b4b4;
}

.search-btn {
    width: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(87, 46, 229);
    cursor: pointer;
    padding: 20px;
}

.reset-btn {
    position: absolute;
    width: 60px;
    height: 61px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    right: 80px;
    font-size: 30px;
    padding: 15px;
}

.search-btn img {
    width: 20px;
    height: auto;
}


/* DESKTOP STYLES */
@media (min-width: 751px) {
    .search-bars {
        position: relative;
        width: 65%;
        display: flex;
        flex-direction: column;
    }
}

/* MOBILE STYLES */
@media (min-width: 320px) and (max-width: 750px) {
    .search-bars {
        position: relative;
        width: 85%;
        display: flex;
        flex-direction: column;
    }
}