@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App.light {
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.App.dark {
  width: 100%;
  background-color: #373737;
  transition: all 0.3s ease-in-out;
}



.btn {
    width: 148px;
    height: 43px;
    border: none;
    outline: none;
    padding: 5px;
    transition: all .2s ease-in-out;
    font-weight: bold;
    cursor: pointer;
}

.btn:focus {
    box-shadow: 0px 0px 5px 0px  rgb(201, 201, 201);
}
.loading-container {
    width: 100%;
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding-top: 100px;
}

.loading-container h3 {
    margin-top: 20px;
    padding: 0px 20px;
}
.search-box-all {
    position: absolute;
    width: 100%;
    z-index: 22;
    border-radius: 50px;
    border: 1px solid rgb(172, 172, 172);
    overflow: hidden;
    transition: all .3s ease-in-out;
}

.suggestions-box.light {
    list-style: none;
    padding: 10px 30px;
    background-color: rgb(245, 245, 245);
    transition: all .3s ease-in-out;
}

.suggestions-box.dark {
    list-style: none;
    padding: 10px 30px;
    background-color: rgb(82, 82, 82);
    color: white;
    transition: all .3s ease-in-out;
}

.search-bars ul li {
    margin-bottom: 10px;
    cursor: pointer;
    transition: all .3s ease-in-out;
}

.search-bars ul li:hover {
    margin-left: 3px;
    text-shadow: 0px 0px 3px rgb(80,227,194);
}

.search-container{
    display: -webkit-flex;
    display: flex;
    transition: all .3s ease-in-out;
}

.search-bar.light {
    width: 100%;
    font-size: 18px;
    padding: 20px 30px;
    border: none;
    outline: none;
    background-color: transparent;
}

.search-bar.dark {
    width: 100%;
    font-size: 18px;
    padding: 20px 30px;
    border: none;
    outline: none;
    background-color: transparent;
    color: white;
}

.search-box-all:focus-within {
    outline: none;
    box-shadow: 0px 0px 6px 0px #b4b4b4;
}

.search-btn {
    width: 86px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: rgb(87, 46, 229);
    cursor: pointer;
    padding: 20px;
}

.reset-btn {
    position: absolute;
    width: 60px;
    height: 61px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: transparent;
    right: 80px;
    font-size: 30px;
    padding: 15px;
}

.search-btn img {
    width: 20px;
    height: auto;
}


/* DESKTOP STYLES */
@media (min-width: 751px) {
    .search-bars {
        position: relative;
        width: 65%;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
    }
}

/* MOBILE STYLES */
@media (min-width: 320px) and (max-width: 750px) {
    .search-bars {
        position: relative;
        width: 85%;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
    }
}

/* DESKTOP STYLES */
@media (min-width: 751px) {
    .header {
        width: 100%;
        height: 100px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        padding: 0px 80px;
    }
}

/* MOBILE STYLES */
@media (min-width: 320px) and (max-width: 750px) {
    .header {
        width: 100%;
        height: 100px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        padding: 0px 20px;
    }
}
.search {
    position: relative;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 30px 0px;
    z-index: 1;
}

/* DESKTOP STYLES */
@media (min-width: 751px) {
    .search h1 {
        font-size: 36px;
    }

    .search h3 {
        margin-top: 79px;
        font-size: 24px;
    }

    .search > img {
        width: 50%;
        height: auto;
        margin-top: 14px;
    }
}

/* MOBILE STYLES */
@media (min-width: 320px) and (max-width: 750px) {
    .search h1 {
        font-size: 28px;
        padding: 0px 20px;
    }

    .search h3 {
        margin-top: 79px;
        font-size: 20px;
        padding: 0px 20px;
    }

    .search > img {
        width: 75%;
        height: auto;
        margin-top: 14px;
    }
}
.body.light {
    position: relative;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    background-color:  #E7E7E7;
    color: #572EE5;
    transition: all .3s ease-in-out;
}
.body.dark {
    position: relative;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: #212121;
    color: white;
    transition: all .3s ease-in-out;
}

 .gif-container-loading {
    width: 100%;
    height: 100%;
}

/* DESKTOP STYLES */
@media (min-width: 751px) {
    .gif-container {
        -webkit-column-count: 3;
                column-count: 3;
        grid-column-gap: 15px;
        -webkit-column-gap: 15px;
                column-gap: 15px;
        width: 85%;
        height: 100%;
        padding-top: 20px;
    }
}

/* MOBILE STYLES */
@media (min-width: 320px) and (max-width: 750px) {
    .gif-container {
        -webkit-column-count: 2;
                column-count: 2;
        grid-column-gap: 10px;
        -webkit-column-gap: 10px;
                column-gap: 10px;
        width: 97%;
        height: 100%;
        padding-top: 10px;
    }
}

/* DESKTOP STYLES */
@media (min-width: 751px) {
    .welcome-message {
        width: 100%;
        height: 100vh;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        padding-top: 100px;
    }
    
}

/* MOBILE STYLES */
@media (min-width: 320px) and (max-width: 750px) {
    .welcome-message {
        width: 85%;
        height: 100vh;
        display: -webkit-flex;
        display: flex;
        padding-top: 100px;
    }    
}

/* DESKTOP STYLES */
@media (min-width: 751px) {
    .noresult-message {
        width: 100%;
        height: 100vh;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        padding-top: 100px;
    }
}

/* MOBILE STYLES */
@media (min-width: 320px) and (max-width: 750px) {
    .noresult-message {
        width: 85%;
        height: 100vh;
        display: -webkit-flex;
        display: flex;
        padding-top: 100px;
    }
}

.gif-card img {
    border-radius: 5px;
    width: 100%;
    height: auto;
    box-shadow: 0px 0px 5px 0px rgba(179, 179, 179, 0.829);
}


/* DESKTOP STYLES */
@media (min-width: 751px) {
    .gif-card {
        width: 100%;
        height: auto;
        margin-bottom: 10px;
        cursor: pointer;
    }
}

/* MOBILE STYLES */
@media (min-width: 320px) and (max-width: 750px) {
    .gif-card {
        width: 100%;
        height: auto;
        margin-bottom: 5px;
        cursor: pointer;
    }
}
.modal-container {
    position: fixed;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background: rgba(165, 165, 165, 0.596);
    padding: 20px;
    color: white;
    z-index: 2;
}

/* DESKTOP STYLES */
@media (min-width: 751px) {

    .modal-gif {
        position: relative;
        width: 32%;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        border-radius: 10px;
        overflow: hidden;
    }

    .modal-gif img {
        width: 100%;
        height: auto;
    }

    .desktop-message {
        display: block;
        padding: 15px;
    }

    .mobile-message {
        display: none;
    }
    
}

/* MOBILE STYLES */
@media (min-width: 320px) and (max-width: 750px) {
    
    .modal-gif {
        position: relative;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        border-radius: 10px;
        overflow: hidden;
        z-index: 1;
    }

    .modal-gif img {
        width: 100%;
        height: auto;
    }

    .desktop-message {
        display: none;
    }

    .mobile-message {
        display: block;
        padding: 15px;
    }
    
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
}

