.loading-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
}

.loading-container h3 {
    margin-top: 20px;
    padding: 0px 20px;
}