
.gif-card img {
    border-radius: 5px;
    width: 100%;
    height: auto;
    box-shadow: 0px 0px 5px 0px rgba(179, 179, 179, 0.829);
}


/* DESKTOP STYLES */
@media (min-width: 751px) {
    .gif-card {
        width: 100%;
        height: auto;
        margin-bottom: 10px;
        cursor: pointer;
    }
}

/* MOBILE STYLES */
@media (min-width: 320px) and (max-width: 750px) {
    .gif-card {
        width: 100%;
        height: auto;
        margin-bottom: 5px;
        cursor: pointer;
    }
}