.search {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 0px;
    z-index: 1;
}

/* DESKTOP STYLES */
@media (min-width: 751px) {
    .search h1 {
        font-size: 36px;
    }

    .search h3 {
        margin-top: 79px;
        font-size: 24px;
    }

    .search > img {
        width: 50%;
        height: auto;
        margin-top: 14px;
    }
}

/* MOBILE STYLES */
@media (min-width: 320px) and (max-width: 750px) {
    .search h1 {
        font-size: 28px;
        padding: 0px 20px;
    }

    .search h3 {
        margin-top: 79px;
        font-size: 20px;
        padding: 0px 20px;
    }

    .search > img {
        width: 75%;
        height: auto;
        margin-top: 14px;
    }
}