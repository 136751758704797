
 .gif-container-loading {
    width: 100%;
    height: 100%;
}

/* DESKTOP STYLES */
@media (min-width: 751px) {
    .gif-container {
        column-count: 3;
        column-gap: 15px;
        width: 85%;
        height: 100%;
        padding-top: 20px;
    }
}

/* MOBILE STYLES */
@media (min-width: 320px) and (max-width: 750px) {
    .gif-container {
        column-count: 2;
        column-gap: 10px;
        width: 97%;
        height: 100%;
        padding-top: 10px;
    }
}