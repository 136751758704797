.modal-container {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(165, 165, 165, 0.596);
    padding: 20px;
    color: white;
    z-index: 2;
}

/* DESKTOP STYLES */
@media (min-width: 751px) {

    .modal-gif {
        position: relative;
        width: 32%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;
        overflow: hidden;
    }

    .modal-gif img {
        width: 100%;
        height: auto;
    }

    .desktop-message {
        display: block;
        padding: 15px;
    }

    .mobile-message {
        display: none;
    }
    
}

/* MOBILE STYLES */
@media (min-width: 320px) and (max-width: 750px) {
    
    .modal-gif {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;
        overflow: hidden;
        z-index: 1;
    }

    .modal-gif img {
        width: 100%;
        height: auto;
    }

    .desktop-message {
        display: none;
    }

    .mobile-message {
        display: block;
        padding: 15px;
    }
    
}