
.btn {
    width: 148px;
    height: 43px;
    border: none;
    outline: none;
    padding: 5px;
    transition: all .2s ease-in-out;
    font-weight: bold;
    cursor: pointer;
}

.btn:focus {
    box-shadow: 0px 0px 5px 0px  rgb(201, 201, 201);
}