.App.light {
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.App.dark {
  width: 100%;
  background-color: #373737;
  transition: all 0.3s ease-in-out;
}

